import { Box, Carousel, Container, Heading, Text } from 'atomic'
import React from 'react'
import { BuilderProps, removeKeys } from '../helpers/BuilderPropCleaner'
import Image from 'next/image'
import { ouTheme, ThemeColors, themeVars } from 'atomic/styles/theme.css'
import Button, { ButtonType } from 'atomic/components/atoms/button/Button'
import { Link } from 'components/link/Link'
import * as css from './TextImage.css'
import StartCreatingButton from 'components/start_creating_button/StartCreatingButton'
import { defaultCreateButtonKey } from 'types/LokalizeKeys'

type ButtonItem = {
  text: string
  type: ButtonType
  createLink: boolean
  link: string
  target: boolean
  size: 's' | 'm' | 'l'
  productType: 'book' | 'print'
}

type List = {
  heading: string
  startIndex: number
  dotColor: keyof ThemeColors
  listItem: ListItem[]
}

type ListItem = {
  subheading: string
  text: string
}

type Slide = {
  src: string
  altText: string
}

type Paginator = {
  color: 'light' | 'dark'
  position: 'floating' | 'bottom'
}

type TextImageProps = {
  backgroundColor?: keyof ThemeColors
  fullbleedLayout: boolean
  mirroredLayout: boolean
  verticalLayout: boolean
  subheadingCheck: boolean
  subheading: string
  heading: string
  headingPosition: 'left' | 'center' | 'right'
  ingress?: string
  text: string
  textPosition: 'left' | 'center' | 'right'
  addList?: boolean
  list?: List
  invertedTextColor: boolean
  addButton: boolean
  button: ButtonItem
  image: string
  altText: string
  fullbleedImage: boolean
  children: React.ReactNode
  slideshow: boolean
  slides?: Slide[]
  selectPaginator?: Paginator
} & BuilderProps

const TextImage: React.FC<TextImageProps> = ({
  backgroundColor,
  fullbleedLayout = false,
  mirroredLayout = false,
  verticalLayout = false,
  subheadingCheck = false,
  subheading,
  heading,
  headingPosition = 'left',
  ingress,
  text,
  textPosition = 'left',
  addList = false,
  list,
  invertedTextColor = false,
  addButton = false,
  button,
  image,
  altText,
  fullbleedImage = false,
  children,
  slideshow = false,
  slides,
  selectPaginator,
  ...props
}) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })

  return (
    <>
      {fullbleedImage && (
        <Box position={'relative'} backgroundColor={fullbleedLayout ? backgroundColor : undefined} {...cleanProps}>
          <Box
            display={{ mobile: slideshow ? 'none' : 'block', desktop: 'block' }}
            className={css.imageContainer}
            style={mirroredLayout ? { left: 0 } : { right: 0 }}
          >
            {slideshow && slides && (
              <Carousel
                paginatorColor={selectPaginator?.color ?? 'dark'}
                paginatorPosition={selectPaginator?.position ?? 'floating'}
                items={slides?.map((slide, index) => {
                  return (
                    <Image
                      key={index}
                      fill
                      src={slide.src}
                      style={{ objectFit: 'cover' }}
                      alt={slide.altText}
                      sizes={`(max-width: ${ouTheme.breakpoints.tablet}) 100vw, 50vw`}
                      loading="lazy"
                      fetchPriority="low"
                    />
                  )
                })}
              />
            )}
            {!slideshow && (
              <Image
                fill
                src={image}
                style={{ objectFit: 'cover' }}
                alt={altText}
                sizes={`(max-width: ${ouTheme.breakpoints.tablet}) 100vw, 50vw`}
                loading="lazy"
                fetchPriority="low"
              />
            )}
          </Box>

          <Box
            display={{ mobile: slideshow ? 'block' : 'none', desktop: 'none' }}
            className={css.mobileSlideshowContainer}
            style={mirroredLayout ? { left: 0 } : { right: 0 }}
          >
            {slideshow && slides && (
              <Carousel
                paginatorColor={selectPaginator?.color ?? 'dark'}
                paginatorPosition={selectPaginator?.position ?? 'floating'}
                items={slides?.map((slide, index) => {
                  return (
                    <Image
                      key={index}
                      src={slide.src}
                      width={0}
                      height={0}
                      style={{ objectFit: 'cover', width: '100%', height: 'auto' }}
                      alt={slide.altText}
                      sizes={`(max-width: ${ouTheme.breakpoints.mobile}) 100vw, 50vw`}
                      loading="lazy"
                      fetchPriority="low"
                    />
                  )
                })}
              />
            )}
            {!slideshow && (
              <Image
                fill
                src={image}
                style={{ objectFit: 'cover' }}
                alt={altText}
                sizes={`(max-width: ${ouTheme.breakpoints.mobile}) 100vw, 50vw`}
                loading="lazy"
                fetchPriority="low"
              />
            )}
          </Box>

          <Container
            paddingY={backgroundColor && backgroundColor !== 'transparent' ? 'xxl' : 'none'}
            backgroundColor={!fullbleedLayout ? backgroundColor : undefined}
            className={css.container}
          >
            {/* Add column to fit layout */}
            {mirroredLayout && <div />}
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              gap={'s'}
              style={{ width: '100%' }}
              paddingLeft={mirroredLayout ? { mobile: 'none', desktop: 'xxxl' } : 'none'}
              paddingRight={!mirroredLayout ? { mobile: 'none', desktop: 'xxxl' } : 'none'}
              paddingTop={{ mobile: fullbleedImage ? 'none' : 'xxl', desktop: 'none' }}
            >
              {subheadingCheck && subheading && (
                <Text
                  fontSize={'l'}
                  fontWeight={'light'}
                  marginBottom={'none'}
                  color={invertedTextColor ? 'white' : 'malm0'}
                  textAlign={headingPosition}
                >
                  {subheading}
                </Text>
              )}
              <Box display={'flex'} flexDirection={'column'} gap={'m'}>
                <Heading as={'h2'} fontSize={'xl'} marginBottom={'none'} textAlign={headingPosition} color={invertedTextColor ? 'white' : 'malm0'}>
                  {heading}
                </Heading>
                {ingress && (
                  <Text
                    fontSize={'l'}
                    fontWeight={'light'}
                    color={invertedTextColor ? 'white' : 'malm0'}
                    textAlign={textPosition}
                    marginBottom={'none'}
                  >
                    {ingress}
                  </Text>
                )}
                <Box
                  fontSize={'base'}
                  fontWeight={'light'}
                  color={invertedTextColor ? 'white' : 'malm0'}
                  textAlign={textPosition}
                  marginBottom={'none'}
                  dangerouslySetInnerHTML={{ __html: text }}
                />
                {addList && list && list.listItem && (
                  <Box display={'flex'} flexDirection={'column'} gap={'m'} paddingBottom={'m'}>
                    {list.heading && (
                      <Text
                        fontSize={'l'}
                        fontWeight={'bold'}
                        className={css.listText}
                        marginBottom={'xs'}
                        color={invertedTextColor ? 'white' : 'malm0'}
                      >
                        {list.heading}
                      </Text>
                    )}
                    {list.listItem.map((item, index) => (
                      <Box key={index} display={'flex'} flexDirection={'column'} gap={'xxxs'}>
                        <Box
                          display={'flex'}
                          alignItems={item.subheading && item.text ? 'flex-start' : 'center'}
                          justifyContent={'flex-start'}
                          gap={'s'}
                        >
                          <Box
                            background={list.dotColor}
                            alignItems={'center'}
                            justifyContent={'center'}
                            textAlign={'center'}
                            style={{ width: themeVars.spaces.l, height: themeVars.spaces.l, borderRadius: '50%', flexShrink: 0 }}
                          >
                            <Text fontFamily="accent" fontWeight={'semibold'} marginBottom={'none'} lineHeight={'xxxxl'}>
                              {index + list.startIndex}
                            </Text>
                          </Box>
                          <Box display={'flex'} flexDirection={'column'} gap={'xxxs'}>
                            <Text
                              fontSize={'base'}
                              fontWeight={'semibold'}
                              className={css.listText}
                              color={invertedTextColor ? 'white' : 'malm0'}
                              marginBottom={'none'}
                              lineHeight={'s'}
                            >
                              {item.subheading}
                            </Text>
                            <Box
                              fontSize={'base'}
                              fontWeight={'light'}
                              color={invertedTextColor ? 'white' : 'malm0'}
                              dangerouslySetInnerHTML={{ __html: item.text }}
                              className={css.listText}
                            />
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                )}
                {addButton && !button?.createLink && (
                  <Link href={button?.link} target={button?.target ? '_blank' : undefined}>
                    <Button
                      variant={button?.type}
                      size={button?.size}
                      style={
                        (invertedTextColor && button?.type === 'secondary') || button?.type === 'toggle'
                          ? { borderColor: 'white', color: 'white', outline: 'none' }
                          : invertedTextColor && button?.type === 'primary'
                            ? { backgroundColor: 'white', color: '#222', outline: 'none' }
                            : { outline: 'none' }
                      }
                    >
                      {button?.text}
                    </Button>
                  </Link>
                )}

                {addButton && button?.createLink && (
                  <StartCreatingButton
                    productType={button?.productType ?? 'book'}
                    buttonText={button?.text}
                    defaultLocalKey={defaultCreateButtonKey}
                    type={button?.type}
                    size={button?.size}
                    style={
                      (invertedTextColor && button?.type === 'secondary') || button?.type === 'toggle'
                        ? { borderColor: 'white', color: 'white', outline: 'none' }
                        : invertedTextColor && button?.type === 'primary'
                          ? { backgroundColor: 'white', color: '#222', outline: 'none' }
                          : { outline: 'none' }
                    }
                  />
                )}

                {children}
              </Box>
            </Box>
          </Container>
        </Box>
      )}

      {!fullbleedImage && (
        <Box display={'flex'} backgroundColor={fullbleedLayout ? backgroundColor : undefined} style={{ width: '100%' }} {...cleanProps}>
          <Container
            display={'flex'}
            flexDirection={
              verticalLayout && !mirroredLayout
                ? { mobile: 'column-reverse', desktop: 'column' }
                : verticalLayout && mirroredLayout
                  ? 'column-reverse'
                  : !verticalLayout && mirroredLayout
                    ? { mobile: 'column-reverse', desktop: 'row-reverse' }
                    : { mobile: 'column-reverse', desktop: 'row' }
            }
            alignItems={'center'}
            justifyContent={'flex-start'}
            gap={{ mobile: 'xl', desktop: 'xxxl' }}
            paddingY={backgroundColor && backgroundColor !== 'transparent' ? 'xxl' : 'none'}
            backgroundColor={!fullbleedLayout ? backgroundColor : undefined}
          >
            <Box display={'flex'} flexDirection={'column'} gap={'s'} style={{ width: '100%' }}>
              {subheadingCheck && subheading && (
                <Text
                  fontSize={'l'}
                  fontWeight={'light'}
                  marginBottom={'none'}
                  color={invertedTextColor ? 'white' : 'malm0'}
                  textAlign={headingPosition}
                >
                  {subheading}
                </Text>
              )}
              <Box display={'flex'} flexDirection={'column'} gap={'m'}>
                <Heading as={'h2'} fontSize={'xl'} marginBottom={'none'} textAlign={headingPosition} color={invertedTextColor ? 'white' : 'malm0'}>
                  {heading}
                </Heading>
                {ingress && (
                  <Text
                    fontSize={'l'}
                    fontWeight={'light'}
                    color={invertedTextColor ? 'white' : 'malm0'}
                    textAlign={textPosition}
                    marginBottom={'none'}
                  >
                    {ingress}
                  </Text>
                )}
                <Box
                  fontSize={'base'}
                  fontWeight={'light'}
                  color={invertedTextColor ? 'white' : 'malm0'}
                  textAlign={textPosition}
                  marginBottom={'none'}
                  dangerouslySetInnerHTML={{ __html: text }}
                />
                {addList && list && list.listItem && (
                  <Box display={'flex'} flexDirection={'column'} gap={'m'}>
                    {list.heading && (
                      <Text
                        fontSize={'l'}
                        fontWeight={'bold'}
                        className={css.listText}
                        marginBottom={'xs'}
                        color={invertedTextColor ? 'white' : 'malm0'}
                      >
                        {list.heading}
                      </Text>
                    )}
                    {list.listItem.map((item, index) => (
                      <Box key={index} display={'flex'} flexDirection={'column'} gap={'xxxs'}>
                        <Box
                          display={'flex'}
                          alignItems={item.subheading && item.text ? 'flex-start' : 'center'}
                          justifyContent={'flex-start'}
                          gap={'s'}
                        >
                          <Box
                            background={list.dotColor}
                            alignItems={'center'}
                            justifyContent={'center'}
                            textAlign={'center'}
                            style={{ width: themeVars.spaces.l, height: themeVars.spaces.l, borderRadius: '50%', flexShrink: 0 }}
                          >
                            <Text fontFamily="accent" fontWeight={'semibold'} marginBottom={'none'} lineHeight={'xxxxl'}>
                              {index + list.startIndex}
                            </Text>
                          </Box>
                          <Box display={'flex'} flexDirection={'column'} gap={'xxxs'}>
                            <Text
                              fontSize={'base'}
                              fontWeight={'semibold'}
                              className={css.listText}
                              color={invertedTextColor ? 'white' : 'malm0'}
                              marginBottom={'none'}
                              lineHeight={'s'}
                            >
                              {item.subheading}
                            </Text>
                            <Box
                              fontSize={'base'}
                              fontWeight={'light'}
                              color={invertedTextColor ? 'white' : 'malm0'}
                              dangerouslySetInnerHTML={{ __html: item.text }}
                              className={css.listText}
                            />
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                )}
                {addButton && !button?.createLink && (
                  <Link href={button?.link} target={button?.target ? '_blank' : undefined}>
                    <Button
                      variant={button?.type}
                      size={button?.size}
                      style={
                        (invertedTextColor && button?.type === 'secondary') || button?.type === 'toggle'
                          ? { borderColor: 'white', color: 'white', outline: 'none' }
                          : invertedTextColor && button?.type === 'primary'
                            ? { backgroundColor: 'white', color: '#222', outline: 'none' }
                            : { outline: 'none' }
                      }
                    >
                      {button?.text}
                    </Button>
                  </Link>
                )}

                {addButton && button?.createLink && (
                  <StartCreatingButton
                    productType={button?.productType ?? 'book'}
                    buttonText={button?.text}
                    defaultLocalKey={defaultCreateButtonKey}
                    type={button?.type}
                    size={button?.size}
                    style={
                      (invertedTextColor && button?.type === 'secondary') || button?.type === 'toggle'
                        ? { borderColor: 'white', color: 'white', outline: 'none' }
                        : invertedTextColor && button?.type === 'primary'
                          ? { backgroundColor: 'white', color: '#222', outline: 'none' }
                          : { outline: 'none' }
                    }
                  />
                )}

                {children}
              </Box>
            </Box>
            <Box
              display={'flex'}
              justifyContent={verticalLayout ? 'center' : { mobile: 'center', desktop: 'flex-start' }}
              position={'relative'}
              style={{ width: '100%' }}
            >
              {slideshow && slides && (
                <Carousel
                  paginatorColor={selectPaginator?.color ?? 'dark'}
                  paginatorPosition={selectPaginator?.position ?? 'floating'}
                  items={slides?.map((slide, index) => {
                    return (
                      <Image
                        key={index}
                        src={slide.src}
                        width={0}
                        height={0}
                        style={{ objectFit: 'cover', width: '100%', height: 'auto' }}
                        alt={slide.altText}
                        sizes={`(max-width: ${ouTheme.breakpoints.tablet}) 100vw, 50vw`}
                        loading="lazy"
                        fetchPriority="low"
                      />
                    )
                  })}
                />
              )}
              {!slideshow && (
                <Image
                  src={image}
                  width={0}
                  height={0}
                  style={{ width: '100%', height: 'auto', maxWidth: 715 }}
                  alt={altText}
                  sizes={`(max-width: ${ouTheme.breakpoints.tablet}) 100vw, 50vw`}
                  loading="lazy"
                  fetchPriority="low"
                />
              )}
            </Box>
          </Container>
        </Box>
      )}
    </>
  )
}

export default TextImage
